import React from "react";
import QualityPolicyBanner from "./QualityPolicyBanner";
import QualityPolicySecondComp from "./QualityPolicySecondComp";
import QualityPolicyThirdComp from "./QualityPolicyThirdComp";

function QualityPolicy() {
  return (
    <>
      <QualityPolicyBanner />
      <QualityPolicySecondComp />
      <QualityPolicyThirdComp />
    </>
  );
}

export default QualityPolicy;
