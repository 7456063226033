import React from "react";
import Layout from "components/layout";
import QualityPolicy from "components/QualityPolicy/QualityPolicy";
import SEO from "components/seo";

function Index() {
  const title = "Quality Policy | Tomedes";
  const description =
    "Tomedes' comprehensive quality policy is designed to bring you high-quality translation services whenever you need it.";
  const keywords = "ISO, certificates, iso, certificate";
  const slug = "/quality-policy";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <QualityPolicy />
    </Layout>
  );
}

export default Index;
