import React from "react";
// import checkSvg from "assets/check-lg.svg";

function QualityPolicyThirdComp() {

  const checkSvg = "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg";

  return (
    <div className="bg-linear-blueBg bg-cover bg-center bg-no-repeat py-16">
      <div className="w-full px-9 sm:px-0 sm:w-10/12 max-w-5xl mx-auto">
        <h2 className="text-40 leading-[55px] text-midBlue text-center font-secondary font-semibold mb-7">
          What does our comprehensive
          <span className="block">quality policy allow us to do?</span>
        </h2>
        <p className="text-lg leading-8 text-center text-midBlue mb-10">
          Approaching QA with an excellent foundation helps us to consistently
          provide our clients with next-level service. Our quality standards
          continually elevate our work, which is why we believe that a
          successful approach to quality must be holistic and operate at all
          points, at all levels of our service.
        </p>
        <div className="flex flex-col gap-8 lg:gap-16 mb-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 lg:gap-28">
            <h3 className="text-[26px] leading-10 text-midBlue font-secondary font-medium">
              <span className="block text-orange">More than translation:</span>
              expertise in a wide range of sectors and industries
            </h3>
            <p className="text-left leading-8 text-midBlue font-opensans">
              At Tomedes we strive to go above and beyond in terms of the
              language services we provide. This is why our network is composed
              of language professionals from a diverse range of industry
              backgrounds.
              <span className="block">
                Apart from their linguistic talents, they bring specialist
                knowledge and experience that helps them work with complicated
                concepts or industry jargon with ease.
              </span>
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 lg:gap-28">
            <h3 className="text-[26px] leading-10 text-midBlue font-secondary font-medium">
              Quality translations in
              <span className="block text-orange">120 languages</span>
              and 950+ language pairs
            </h3>
            <p className="text-left leading-8 text-midBlue font-opensans">
              Consistency is difficult to replicate when working with language
              specialists in over a hundred languages, but our quality policy
              ensures that we are working with the most reliable professionals
              who place the same value on quality as we do.
              <span className="block">
                We trust our people to put out work worthy of the Tomedes
                guarantee.
              </span>
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 lg:gap-28">
            <h3 className="text-[26px] leading-10 text-midBlue font-secondary font-medium">
              <span className="text-orange">Quality </span>
              in every <br /> interaction and 950+ language pairs
            </h3>
            <p className="text-left leading-8 text-midBlue font-opensans">
              We know we provide language services of the highest quality, which
              is why we can approach every interaction with confidence. Every
              assurance we provide is backed by years of success operating on
              the principle of pursuing holistic quality.
              <span className="block">
                We hope to pass that confidence on to you, our valued clients,
                to give you the best experience with Tomedes as your partner in
                language.
              </span>
            </p>
          </div>
        </div>
        <h2 className="text-[35px] leading-[55px] text-midBlue font-secondary font-semibold mb-8">
          What you can expect from us
        </h2>
        <ul className="text-xl leading-7 text-midBlue font-opensans font-semibold grid grid-cols-1 lg:grid-cols-2 lg:gap-20">
          <div>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6  h-6" />
              Quality translations with the human touch
            </li>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6  h-6" />
              Over 120 Languages and 950+ language pairs
            </li>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6 h-6 " />
              Real-time interpretation for events, trade shows, seminars and
              more
            </li>
          </div>
          <div>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6  h-6" />
              Quick results, within 24 hours
            </li>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6 h-6 " />
              QA and LQA
            </li>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6 h-6 " />
              One-Year Guarantee on all projects
            </li>
            <li className="flex gap-3 mb-5">
              <img src={checkSvg} alt="" className="text-orange w-6 h-6 " />
              Constant customer-centric focus and feedback
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default QualityPolicyThirdComp;
