import React from "react";
import { Link } from "gatsby";
import first from "assets/quality-policy/1.svg";
import second from "assets/quality-policy/2.svg";
import third from "assets/quality-policy/3.svg";
import fourth from "assets/quality-policy/4.svg";
import fifth from "assets/quality-policy/5.svg";
import sixth from "assets/quality-policy/6.svg";
import seventh from "assets/quality-policy/7.svg";

function QualityPolicySecondComp() {
  return (
    <div className="linear-blueBg bg-cover bg-center bg-no-repeat py-16">
      <div className="w-full px-9 sm:px-0 sm:w-10/12 max-w-5xl mx-auto">
        <div className="flex justify-center items-center gap-3 mb-8">
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/9001_big.webp"
            alt="ISO 9001"
            className="hidden md:block"
          />
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/9001.webp"
            alt="ISO 9001"
            className="md:hidden"
          />
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/17100_big.webp"
            alt="ISO 17100"
            className="hidden md:block"
          />
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/17100.webp"
            alt="ISO 17100"
            className="md:hidden"
          />
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/18587_big.webp"
            alt="ISO 18578"
            className="hidden md:block"
          />
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/18587.webp"
            alt="ISO 18578"
            className="md:hidden"
          />
        </div>
        <h2 className="text-[32px] sm:text-45 leading-[55px] text-midBlue text-center font-secondary font-semibold mb-7">
          Fully ISO-<span className="text-orange">Certified</span>
        </h2>
        <p className="text-lg leading-8 text-midBlue text-center font-opensans mb-7">
          Our commitment to quality is recognized by ISO, the International
          Organization for Standardization. ISO is the highest authoritative
          body in developing global industry standards and bestowing
          accreditation on companies and institutions that meet them around the
          world.
        </p>
        <p className="text-lg leading-8 text-midBlue text-center font-opensans mb-7">
          Tomedes is fully ISO-certified in three categories relevant to
          language service providers.&nbsp;
          <Link to="/iso-certifications" className="text-orange hover:text-newOrange-100 underline">
            Read here
          </Link>
          &nbsp;to learn more.
        </p>
        <div className="pt-[100px] lg:grid grid-cols-2 gap-20 pb-[80px]">
          <div>
            <h2 className="text-40 leading-[55px] text-midBlue font-secondary font-semibold mb-5">
              Our three-part
              <br />
              approach to
              <span className="block text-orange">quality assurance</span>
            </h2>
            <p className="text-lg leading-8 text-midBlue font-opensans">
              At Tomedes, we believe that the pursuit of quality requires a
              holistic perspective. Quality is something that can only be gained
              by seeking it at every step of the process—in line with this
              principle, we take a three-part approach to quality assurance that
              covers not only the duration of the project, but also what comes
              before and after.
            </p>
          </div>
          <img
            src="https://tomedes.gumlet.io/assets/quality-policy/image_1.webp"
            alt=""
            className="hidden lg:block max-h-96"
          />
        </div>
        <h3 className="text-[26px] leading-9 text-midBlue font-secondary font-medium mb-7">
          <span className="text-orange">Comprehensive</span> vendor management
          process
        </h3>
        <p className="text-lg leading-8 text-midBlue font-opensans mb-6">
          Before the project has even begun, we already set the stage for
          quality through rigorous and comprehensive vendor management. Our
          vendor management team has scouted some of the best linguistic talent
          out there, creating our network of 20,000+ language professionals with
          a diverse repertoire of language skills and professional backgrounds.
        </p>
        <p className="text-lg leading-8 text-midBlue font-opensans mb-10">
          This allows us to vet and select the right people for your project
          with accuracy and ease. Each one has been carefully verified with
          appropriate background checks to attest their qualifications and
          routine skill tests to determine the quality of their work.
        </p>
        <h3 className="text-[26px] leading-9 text-midBlue font-secondary font-medium mb-7">
          Our project &nbsp;
          <span className="text-orange">quality assurance cycle</span>
        </h3>
        <p className="text-lg leading-8 text-midBlue font-opensans mb-6">
          Attention to quality is maintained in our language projects through
          the QA cycle proper. Our standard QA process involves multiple
          automated and human checks before the final documents are handed back
          to the client. We use top-of-the-line language QA management tools
          like xBench as well as our own proprietary tools to conduct automated
          checks and manage revisions.
        </p>
        <p className="text-lg leading-8 text-midBlue font-opensans mb-10">
          Our full QA suite includes options for further review by another
          language professional. You can view the entire process below:
        </p>
        {/* cycle */}
        <div>
          <div className="hidden xl:grid grid-cols-4 gap-20">
            <div>
              <div className="relative">
                <img src={first} alt="" />
                <div className="w-32 pb-[1px] bg-orange absolute left-32 bottom-10"></div>
              </div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                Translation
                <span className="block invisible">for alignment</span>
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc">
                <li>Step completion</li>
              </ul>
            </div>
            <div>
              <div className="pl-14">
                <div className="relative inline-block">
                  <img src={second} alt="" />
                  <div className="w-32 pb-[1px] bg-orange absolute left-32 bottom-10"></div>
                </div>
                <p className="text-xl leading-9 font-secondary font-medium mb-2">
                  QA Checks
                  <span className="block invisible">for alignment</span>
                </p>
              </div>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc flex flex-col gap-2">
                <li>
                  Tech team runs automated QA checks (in-CAT or external tools
                  such as Xbench)
                </li>
                <li>
                  Translation is bounced back to the linguists if necessary, to
                  make sure only False Positives are left
                </li>
              </ul>
            </div>
            <div>
              <div className="pl-14">
                <div className="relative inline-block">
                  <img src={third} alt="" />
                  <div className="w-32 pb-[1px] bg-orange absolute left-32 bottom-10"></div>
                </div>
                <p className="text-xl leading-9 font-secondary font-medium mb-2">
                  Review
                  <span className="block invisible">for alignment</span>
                </p>
              </div>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc flex flex-col gap-2">
                <li>
                  The translation is sent for Review to check instructions
                  compliance (non- translatables, TB, Style, Glossary, etc.)
                </li>
              </ul>
            </div>
            <div>
              <div className="pl-14">
                <div className="relative inline-block">
                  <img src={fourth} alt="" />
                </div>
                <p className="text-xl leading-9 font-secondary font-medium mb-2">
                  Review (Optional)
                </p>
              </div>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc flex flex-col gap-2">
                <li>
                  The translation is sent to an external proofreader or editor
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden xl:grid grid-cols-3 justify-items-center my-16 gap-20">
            <div>
              <div className="pl-14">
                <div className="relative inline-block">
                  <img src={fifth} alt="" />
                  <div className="w-32 pb-[1px] bg-orange absolute left-32 bottom-10"></div>
                </div>
                <p className="text-xl leading-9 font-secondary font-medium mb-2">
                  Automated
                  <br /> QA Check
                </p>
              </div>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc flex flex-col gap-2">
                <li>
                  Run by tech team (in-CAT or external tools such as Xbench)
                </li>
              </ul>
            </div>
            <div>
              <div className="pl-14">
                <div className="relative inline-block">
                  <img src={sixth} alt="" />
                  <div className="w-32 pb-[1px] bg-orange absolute left-32 bottom-10"></div>
                </div>
                <p className="text-xl leading-9 font-secondary font-medium mb-2">
                  LQA
                  <br />
                  (Optional)
                </p>
              </div>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc flex flex-col gap-2">
                <li>
                  The translation is sent to an external proofreader or editor
                </li>
              </ul>
            </div>
            <div>
              <div className="pl-14">
                <div className="relative inline-block">
                  <img src={seventh} alt="" />
                </div>
                <p className="text-xl leading-9 font-secondary font-medium mb-2">
                  Hand back
                  <br />
                  to the PM
                </p>
              </div>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc flex flex-col gap-2">
                <li>That runs a final check to see project adherence</li>
              </ul>
            </div>
            {/* fjfl */}
          </div>
          <div className="xl:hidden flex flex-col gap-6 mb-12">
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                1. Translation
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9">
                <li>Step completion</li>
              </ul>
            </div>
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                2. QA Checks
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9 flex flex-col gap-2">
                <li>
                  Tech team runs automated QA checks (in-CAT or external tools
                  such as Xbench)
                </li>
                <li>
                  Translation is bounced back to the linguists if necessary, to
                  make sure only False Positives are left
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                3. Review
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9 flex flex-col gap-2">
                <li>
                  The translation is sent for Review to check instructions
                  compliance (non- translatables, TB, Style, Glossary, etc.)
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                4. Review (Optional)
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9 flex flex-col gap-2">
                <li>
                  The translation is sent to an external proofreader or editor
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                5. Automated QA Check
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9 flex flex-col gap-2">
                <li>
                  Run by tech team (in-CAT or external tools such as Xbench)
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                6. LQA (Optional)
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9 flex flex-col gap-2">
                <li>
                  The translation is sent to an external proofreader or editor
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xl leading-9 font-secondary font-medium mb-2">
                7. Hand back to the PM
              </p>
              <ul className="text-base leading-6 text-midBlue font-opensans list-disc pl-9 flex flex-col gap-2">
                <li>That runs a final check to see project adherence</li>
              </ul>
            </div>
          </div>
        </div>
        <p className="text-lg leading-8 text-midBlue font-opensans mb-12">
          We believe that a commitment to quality is a commitment to learning
          and growth. QA is an iterative process, and as such, our language
          professionals undergo routine audits of their work and are given
          regular feedback to ensure that they’re able to stay on top of their
          game from project to project.
        </p>
        <h3 className="text-[26px] text-midBlue font-secondary font-medium mb-7">
          1-year <span className="text-orange">guarantee</span>
        </h3>
        <p className="text-lg leading-8 text-midBlue font-opensans">
          Our commitment to quality does not end with the completion of the
          project. We expect nothing less than perfection in language to satisfy
          our discerning clients, which is why Tomedes provides its clients with
          a&nbsp;
          <span  className="">
            one-year guarantee
          </span>
          &nbsp;for all language projects. Any errors you may find during this
          period will be dealt with promptly at no additional cost.
        </p>
      </div>
    </div>
  );
}

export default QualityPolicySecondComp;
